<ngx-spinner type="ball-spin-clockwise" size="medium"></ngx-spinner>

<div class="uploadCss">
  <h3 class="header">eAudit File Upload Form</h3>

  <div class="row">
    <div class="col-8">
      <label class="btn btn-default p-0">
        <input #fileInput type="file" multiple id="file1" (click)="fileInput.value = null" value="" (change)="selectFile($event)" accept=".png,.jpg,.jpeg,.docx,
            .doc,.txt,.pdf,.xls,.xlsx,.png,.gif,.mp4,.mp3" />
      </label>
    </div>

    <div class="col-4">
      <button class="btn btn-success btn-sm" [disabled]="!selectedFiles || fileInfos.length > 49 || disableUploadError || recaptchaError " (click)="uploadEachFile()">
        Upload
      </button>
    </div>
  </div>
  <div class="alert alert-info" role="alert">
    <i class="bi bi-info-circle-fill"></i>
    Accepted types: png,jpg,jpeg,docx,doc,txt,pdf,xls,xlsx,gif,mp4,mp3.
  </div>
  <div class="alert alert-info" role="alert">
    <i class="bi bi-info-circle-fill"></i>
    File Size Limit:100mb
  </div>
  <div class="alert alert-info" role="alert">
    <i class="bi bi-info-circle-fill"></i>
    File Upload Limit: 10 files
  </div>

  <div *ngIf="errorMessages.length > 0">
    <div *ngFor="let message of errorMessages"  class="alert  message_alert_class" role="alert">
      <i class="bi-exclamation-octagon-fill danger-icon-style"></i>
    
      {{ message }}
    </div>
  </div>



  <div class="alert alert-success d-flex align-items-center" role="alert" *ngIf="successMessage?.length > 0">
    <i class="bi bi-check-circle-fill icon-style"></i> {{ successMessage }}
  </div>

  <div class="alert alert-danger error-message-style" role="alert" *ngIf="errorSubmitMsg?.length > 0">
    <i class="bi-exclamation-octagon-fill danger-icon-style"></i>
    {{ errorSubmitMsg }}
  </div>

  <div class="alert alert-danger error-message-style" role="alert" *ngIf="errorSubmitMsgrecaptcha?.length > 0">
    <i class="bi-exclamation-octagon-fill danger-icon-style"></i>
    {{ errorSubmitMsgrecaptcha }}
  </div>

  <div class="card mt-3">
    <div class="card-header">List of Files</div>
    <ul class="list-group list-group-flush">
      <li *ngFor="let file of fileInfos" class="list-group-item">
        {{ file?.name }}
        <button type="button" class="close" aria-label="Close" (click)="removeFile(file)">
          <span aria-hidden="true">&times;</span>
        </button>
      </li>
    </ul>
  </div>

  <div *ngIf="reCAPTCHAVisible"> reCAPTCHA Token <br />
    <p>{{reCAPTCHAToken}}</p>
  </div>
  
  <br />
  <div class="form-group">
    <button type="button" (click)="onReset()" class="btn btn-warning btn-lg float-left">
      Back
    </button>

    <button type="submit" class="btn btn-primary btn-lg float-right" [disabled]="fileInfos.length == 0 || uploadedFully || recaptchaError"
    
      (click)="confirmation()">
      Submit
    </button>
  </div>
</div>