<ngx-spinner type="ball-spin-clockwise" size="medium"></ngx-spinner>

<div class="uploadCss">
  <h3 class="header">eAudit File Upload Form</h3>

  <div class="row">
    <div class="col-8">
      <label class="btn btn-default p-0">
        <input #fileInput type="file" multiple id="file1" (click)="fileInput.value = null" value="" (change)="selectFile($event)" accept=".png,.jpg,.jpeg,.docx,
            .doc,.txt,.pdf,.xls,.xlsx,.png,.gif,.mp4,.mp3" />
      </label>
    </div>

    <div class="col-4">
      <button class="btn btn-success btn-sm" [disabled]="!selectedFiles  || disableUploadError || waitTillUpload || recaptchaError " (click)="uploadEachFile()">
        Upload
      </button>
    </div>
  </div>
  <div class="alert alert-info" role="alert">
    <i class="bi bi-info-circle-fill"></i>
    Accepted types: png,jpg,jpeg,docx,doc,txt,pdf,xls,xlsx,gif,mp4,mp3.
  </div>
  <div class="alert alert-info" role="alert">
    <i class="bi bi-info-circle-fill"></i>
    File Size Limit:100mb
  </div>
  <div class="alert alert-info" role="alert">
    <i class="bi bi-info-circle-fill"></i>
    Upload limit: Please upload no more than 10 files at once, including 1 large file, and a total upload limit of 50 files.
  </div>

  <div *ngIf="errorMessages.length > 0">
    <div *ngFor="let message of errorMessages"  class="alert  message_alert_class" role="alert">
      <i class="bi-exclamation-octagon-fill danger-icon-style"></i>
    
      {{ message }}
    </div>
  </div>

  <div  class="alert  message_alert_class" role="alert" *ngIf="failedFiles.length > 0">
      <i class="bi-exclamation-octagon-fill danger-icon-style"></i>
    <h4 style=" font-weight: bold;
    margin-left: 27px;
    list-style-type: disc;
    margin-bottom: 5px;
    margin-top: -28px;
    margin-right: 38px;
  "> List of Failed Files: </h4>
    <ul>
      <li *ngFor="let file of failedFiles"> {{file}}</li>
    </ul>
    </div> 
  



  <div class="alert alert-success d-flex align-items-center" role="alert" *ngIf="successMessage?.length > 0">
    <i class="bi bi-check-circle-fill icon-style"></i> {{ successMessage }}
  </div>

  <div class="alert alert-danger error-message-style" role="alert" *ngIf="errorSubmitMsg?.length > 0">
    <i class="bi-exclamation-octagon-fill danger-icon-style"></i>
    {{ errorSubmitMsg }}
  </div>

  <div class="alert alert-danger error-message-style" role="alert" *ngIf="errorSubmitMsgrecaptcha?.length > 0">
    <i class="bi-exclamation-octagon-fill danger-icon-style"></i>
    {{ errorSubmitMsgrecaptcha }}
  </div>

  <div class="card mt-3">
    <div class="card-header">List of Files</div>
    <ul class="list-group list-group-flush">
      <li *ngFor="let file of fileInfos" class="list-group-item">
        {{ file?.name }}
        <button type="button" class="close" aria-label="Close" (click)="removeFile(file)">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="progress mt-2 flex-grow-1" *ngIf="file.progress !== undefined">
          <div class="progress-bar  progress-bar-animated"
                      role="progressbar" [style.width]="file.progress + '%'" >
                   {{ file?.progress }}%
          </div>
          </div>

      
      </li>
    </ul>
  </div>

  <div *ngIf="reCAPTCHAVisible"> reCAPTCHA Token <br />
    <p>{{reCAPTCHAToken}}</p>
  </div>
  
  <br />
  <div class="form-group">
    <button type="button" (click)="onReset()" class="btn btn-warning btn-lg float-left">
      Back
    </button>

    <button type="submit" class="btn btn-primary btn-lg float-right" [disabled]="fileInfos.length == 0 || uploadedFully || recaptchaError"
    
      (click)="confirmation()">
      Submit
    </button>
  </div>
</div>