export const environment = {
    production: false,
    siteKey: '6LffnmIpAAAAABRdRvOKDd1JHt6hwL0Qqz50aMvm',
    validateURL: 'https://docintake-validation-service-gmboqmwosa-uk.a.run.app/',
    uploadURL:'https://docintake-single-doc-service-gmboqmwosa-uk.a.run.app',
    submissionUrl: 'https://docintake-submission-service-gmboqmwosa-uk.a.run.app',
    domainName: "eaudit",

    maxFileCount :"50",
    maxFilePerUpload:"10",
    maxFileSizeMB:"100",
    maxTotalSizeMB:"1400",
	fileSupported: ".png,.jpg,.jpeg,.docx,.doc,.txt,.pdf,.xls,.xlsx,.png,.gif,.mp4,.mp3",
	
	
	// Currently files size of 20 MB or less is considered small
    perUploadMaxSmallFileCnt : "9",
    perUplpoadMaxSmallFileSizeMB : "20",
    perUploadMaxSmallFileTotalSizeMB : "900",
	
	// Files size over 20 MB is considered large
    perUploadMaxLargeFileCnt : "1",
    perUploadMaxLargeSizeMB :"100",
	perUploadMaxLargeFileTotalSizeMB : "500"
};
